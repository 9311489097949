import Banner from "../../components/common/Banner/Banner";
import BannerContainer from "../../components/common/Banner/BannerContainer";
import FormHeader from "../../components/pages/AuthPage/FormHeader";
import FormFields from "../../components/pages/AuthPage/FormFields";
import { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { useAuthContext } from "../../context/AuthContext/AuthContext";
import axios from "axios";
import { useRouter } from "next/router";
import Head from "next/head";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleLogo from "../../components/pages/AuthPage/components/GoogleLogo";
import useCustomToast from "../../components/utils/useCustomToast";
import Button from "../../components/common/Button";
import FormWrapper from "../../components/pages/AuthPage/components/FormWrapper";
import Seperator from "../../components/pages/AuthPage/components/Seperator";
import { useMixpanelClient } from "../../hooks/useMixpanelClient";

export default function Login() {
  const router = useRouter();
  const { login } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const toast = useCustomToast();

  useEffect(() => {
    useMixpanelClient.track("open_sign_in");
  }, []);

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      router.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginHandler = (e) => {
    e.preventDefault();
    useMixpanelClient.track("sign_in_email");
    const formData = new FormData(e.currentTarget);
    const data = Array.from(formData.entries());
    const email = data[0][1];
    const password = data[1][1];

    if (!(email.length && password.length)) {
      toast({
        type: "error",
        title: "Login failed.",
        message: "Please input all of the fields required",
      });
    } else {
      if (password.length < 8) {
        toast({
          type: "warning",
          title: "Login failed.",
          message: "Password needs to be > 8 characters long",
        });
      } else {
        setLoading(true);
        axios
          .post("/api/auth", {
            email: email,
            password: password,
          })
          .then((res) => res.data.message)
          .then((data) => {
            useMixpanelClient.track("sign_in_successful", {
              type: "email",
            });
            login(data);
            toast({
              type: "success",
              title: "Login successful!",
              message: `Welcome, ${data.fullName}!`,
            });
            let { redirectUrl } = router.query;
            if (!redirectUrl) {
              redirectUrl = "/";
            }
            router.replace(redirectUrl);
          })
          .catch((err) => {
            setLoading(false);
            toast({
              type: "error",
              title: "Login failed.",
              message: err.response.data.message,
            });
          });
      }
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setLoading(true);
      axios
        .post("/api/google", {
          token: tokenResponse.access_token,
        })
        .then((res) => res.data.message)
        .then((data) => {
          login(data);
          useMixpanelClient.track("sign_in_successful", {
            type: "GAuth",
          });
          toast({
            type: "success",
            title: "Login successful!",
            message: `Welcome, ${data.fullName}!`,
          });
          let { redirectUrl } = router.query;
          if (!redirectUrl) {
            redirectUrl = "/";
          }
          router.replace(redirectUrl);
        })
        .catch((err) => {
          setLoading(false);
          toast({
            type: "error",
            title: "Login failed.",
            message: err.response.data.message,
          });
        });
    },
    onError: () => {
      toast({
        type: "error",
        title: "Google login failed.",
        message: "Failed to login with google",
      });
    },
  });

  return (
    <>
      <Head>
        <title>Login</title>
        <link rel='icon' href='/logo.ico' />
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <meta
          name='description'
          content='The easiest customizable URL shortener, ever.'
        />
        <meta
          name='og:title'
          content='The easiest customizable URL shortener, ever.'
        />
      </Head>
      <BannerContainer>
        <Banner />
      </BannerContainer>
      <FormWrapper>
        <FormHeader
          title='Welcome back!'
          tagline="Don't have an account?"
          altTagline='Create yours now'
          href='/register'
          track={"sign_in_to_register"}
        />
        <Button
          onClick={() => {
            useMixpanelClient.track("sign_in_google");
            handleGoogleLogin();
          }}
          additionStyle={"h-[3rem] mt-[1rem] hover:bg-purple-100"}
          type={"outlined"}
        >
          <GoogleLogo />
          Sign in with Google
        </Button>
        <Seperator>or use your email account</Seperator>
        <form
          style={{ display: "flex", flexDirection: "column" }}
          onSubmit={loginHandler}
        >
          <FormFields type='login' />
          <Button
            action={"submit"}
            additionStyle={"h-[3rem] mt-[1rem]"}
            type={loading ? "stale" : "primary"}
            disabled={loading}
          >
            {loading ? <Spinner /> : <>Sign in</>}
          </Button>
        </form>
      </FormWrapper>
    </>
  );
}
