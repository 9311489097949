export default function GoogleLogo() {
  return (
    <svg
      style={{marginRight: "8px"}}
      width="clamp(18px, 3vw, 25px)"
      height="clamp(17px, 3vw, 24px)"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_191_10721)">
        <path
          d="M24.5 12.0935C24.5 11.2593 24.4339 10.4207 24.2928 9.6001H12.98V14.3253H19.4583C19.1895 15.8493 18.0848 17.3834 16.82 18.2401L17.0609 21.1201L20.66 21.1201C22.9296 18.9829 24.5 15.8268 24.5 12.0935Z"
          fill="#4285F4"
        />
        <path
          d="M12.6876 23.9999C15.9313 23.9999 18.6669 22.9483 20.6599 21.133L16.8199 18.2399C15.7416 18.9643 14.3139 19.3003 12.692 19.3003C9.55433 19.3003 6.7345 17.2101 5.77994 14.3999H1.93994V17.4588C3.98164 21.469 8.14017 23.9999 12.6876 23.9999Z"
          fill="#34A853"
        />
        <path
          d="M5.78001 14.4C5.22983 12.9396 5.46983 11.0604 6.02001 9.59998L6.26001 6.71998L1.94 6.47998C0.0771096 9.80267 0.0342906 13.9573 1.89718 17.28L5.78001 14.4Z"
          fill="#FBBC04"
        />
        <path
          d="M12.9191 4.69791C14.6707 4.67174 16.3636 5.30849 17.6322 6.47732L21.1399 3.08859C18.9188 1.07368 15.9709 -0.0340905 12.9191 0.000799855C8.27373 0.000799855 4.02562 2.46761 1.93994 6.48L6.02101 9.6C6.99162 6.78697 9.71384 4.69791 12.9191 4.69791Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0_191_10721">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
